// App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Home } from './Home';
import { NoticeOfPleadings } from './NoticeOfPleadings';

export const App: React.FunctionComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/notice-of-pleadings" element={<NoticeOfPleadings />} />
      </Routes>
    </Router>
  );
};
