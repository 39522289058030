import React from 'react';
import { Stack, Text, Link, FontWeights, IStackTokens, IStackStyles, ITextStyles } from '@fluentui/react';
import logo from './Microsoft-logo_rgb_c-gray.png';
import './App.css';

const boldStyle: Partial<ITextStyles> = { root: { fontWeight: FontWeights.semibold } };
const stackTokens: IStackTokens = { childrenGap: 15 };
const stackStyles: Partial<IStackStyles> = {
  root: {
    width: '960px',
    margin: '0 auto',
    textAlign: 'left',
    color: '#605e5c',
  },
};

export const Home: React.FunctionComponent = () => {
  return (
    <Stack horizontalAlign="center" verticalAlign="center" verticalFill styles={stackStyles} tokens={stackTokens}>
      <img src={logo} alt="logo"/>
      <Text variant="xxLarge" styles={boldStyle}>
        This Domain has been seized by Microsoft
      </Text>
      <Text variant="large">
        <ol className="spaced-list">
          <li>Microsoft Corporation has filed a lawsuit in federal court in the Southern District of New York alleging that the operators of this website have been using the website to sell fraudulently-obtained Microsoft accounts and technology to fraudulently obtain Microsoft and other company accounts.</li>
          <li>Pursuant to a temporary restraining order issued by the federal court overseeing this lawsuit, control of this website domain has been seized by Microsoft Corporation pending the outcome of the litigation.</li>
          <li>Information about the lawsuit can be found here <Link href="/notice-of-pleadings">Notice of pleadings</Link></li>
          <li>For genuine Microsoft services and products, please visit the official Microsoft website at <Link href="https://www.microsoft.com/">www.microsoft.com</Link>.</li>
          <li>Microsoft has made it a top priority to stop the sale of fraudulent or abusive products, or products that falsely claim a connection to Microsoft. Microsoft continuously monitors the Internet and takes action where appropriate, including but not limited to filing civil lawsuits. To those of you who may be considering purchasing these products, we urge you to not do so. By purchasing them, you are supporting unlawful activity. To those creating, selling, or distributing these products, bear in mind that we are watching and taking notice.</li>
        </ol>
      </Text>
    </Stack>
  );
};
