import * as React from 'react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from '@fluentui/react/lib/Stack';
import { Link, Separator, Text } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/react/lib/Styling';
import { getTheme, mergeStyles } from '@fluentui/react';

// Get the Fluent UI theme
const theme = getTheme();

// Define a custom style for the separator
const separatorStyles = mergeStyles({
  selectors: {
    '::before': {
      backgroundColor: theme.palette.neutralDark, // Change this to any color you want
    },
  },
});
// Styles definition
const categoryStackStyles: IStackStyles = {
    root: {
      border: 'solid 1px',
      borderColor: DefaultPalette.neutralLight,
      borderRadius: 10,
      padding: 0,
      marginBottom: 20,
    },
  };
const headerStackItemStyles: IStackItemStyles = {
    root: {
      background: DefaultPalette.neutralLight,
      color: DefaultPalette.black,
      padding: 5,
      borderRadius: 10,
    },
  };

const linkStackItemStyles: IStackItemStyles = {
    root: {
      padding: 10,
    },
  };  
  const linkStackItemStyles2: IStackItemStyles = {
    root: {
      padding: 10,
      marginLeft: 40,
    },
  };   
const stackStyles: Partial<IStackStyles> = {
    root: {
      margin: '100px',
      textAlign: 'left',
      color: '#605e5c',
    },
  };
const stackItemStyles: IStackItemStyles = {
  root: {
    color: DefaultPalette.black,
    padding: 5,
  },
};
const equalStackStyles: React.CSSProperties = {
    flex: 1,
  };
  const textStyles: React.CSSProperties = {
    whiteSpace: 'pre', // This will preserve whitespace
  };
// Tokens definition
const containerStackTokens: IStackTokens = { childrenGap: 5 };

const itemAlignmentsStackTokens: IStackTokens = {
  childrenGap: 5,
  padding: 10,
};

export const NoticeOfPleadings: React.FunctionComponent = () => {
  return (
    <Stack enableScopedSelectors tokens={containerStackTokens} styles={stackStyles}>
        <Stack enableScopedSelectors tokens={itemAlignmentsStackTokens}>
            <Stack.Item align="end" styles={stackItemStyles}>
                <Text variant="mediumPlus">Date of first publication: December 13, 2023</Text>
            </Stack.Item>
            <Stack.Item align="center" >
                <Text variant="mediumPlus"><b>UNITED STATES DISTRICT COURT</b></Text>
            </Stack.Item>
            <Stack.Item align="center" >
                <Text variant="mediumPlus"><b>SOUTHERN DISTRICT OF NEW YORK</b></Text>
            </Stack.Item>
            <Separator className={separatorStyles} />
            <Stack horizontal>
                <Stack style={equalStackStyles}>
                    <Stack.Item align="start" >
                        <Text variant="mediumPlus">MICROSOFT CORPORATION,</Text>
                    </Stack.Item>
                    <Stack.Item align="start" style={textStyles} >
                        <Text variant="mediumPlus">                                                      Plaintiff,</Text>
                    </Stack.Item>
                    <br/>
                    <Stack.Item align="start" >
                        <Text variant="mediumPlus">-against-</Text>
                    </Stack.Item>
                    <br/>
                    <Stack.Item align="start" >
                        <Text variant="mediumPlus">DUONG DINH TU,</Text>
                    </Stack.Item>
                    <Stack.Item align="start" >
                        <Text variant="mediumPlus">LINH VAN NGUYEN, and</Text>
                    </Stack.Item>
                    <Stack.Item align="start" >
                        <Text variant="mediumPlus">TAI VAN NGUYEN</Text>
                    </Stack.Item>
                    <Stack.Item  align="start" style={textStyles} >
                        <Text variant="mediumPlus">                                                      Defendants.</Text>
                    </Stack.Item>
                </Stack>
                <Stack  style={equalStackStyles}>
                    <Stack.Item align="start" >
                        <Text variant="mediumPlus" style={textStyles}>
{`:
:
:
:           Case No. 23-cv-10685
:
:
:
`}
                        </Text>
                    </Stack.Item>
                    <Stack.Item align="start" >
                        <Text variant="mediumPlus">: </Text>
                    </Stack.Item>
                </Stack>

            </Stack>
            <Separator className={separatorStyles} />
            <Stack.Item align="start" >
                <Text variant="mediumPlus"><b>Plaintiff Microsoft Corporation (“Microsoft”) has sued Defendants Duong Dinh Tu, Linh Van Nguyen (a/k/a Nguyen Van Linh), and Tai Van Nguyen, who are associated with the Internet domains set forth in the documents referenced in this communication. Microsoft alleges that Defendants have violated Federal and state law by hosting a cybercriminal operation through these Internet domains, causing unlawful deception of and intrusion into Microsoft’s computer systems; and intellectual property violations to the injury of Microsoft and Microsoft’s customers.  Microsoft seeks a preliminary injunction directing the registries associated with these Internet domains to take all steps necessary to transfer these Internet domains to Microsoft’s control and/or disable access to and operation of these domains, to ensure that changes or access to the Internet domains cannot be made absent a court order and that all content and material associated with these Internet domains are to be isolated and preserved pending resolution of the dispute.  Microsoft seeks a final judgment and permanent injunction, other equitable relief and damages.  Full copies of the pleading documents are available at the links below.</b></Text>
            </Stack.Item>
            <br />
            <Stack.Item align="start" >
                <Text variant="mediumPlus"><b>NOTICE TO DEFENDANTS: READ THESE PAPERS CAREFULLY!  Duong Dinh Tu, Linh Van Nguyen, and Tai Van Nguyen:  A lawsuit has been filed against you.  Within 21 days after service of this summons on you (not counting the day you received it) — or 60 days if you are the United States or a United States agency, or an officer or employee of the United States described in Fed. R. Civ. P. 12 (a)(2) or (3) — you must serve on the plaintiff an answer to the attached complaint or a motion under Rule 12 of the Federal Rules of Civil Procedure.  The answer or motion must be served on the plaintiff or plaintiff’s attorney, whose name and address are: Brian Markley CAHILL GORDON & REINDEL LLP, 32 Old Slip New York, New York 10005.  If you fail to respond, judgment by default will be entered against you for the relief demanded in the complaint.  You also must file your answer or motion with the court.  If you have questions, you should consult with your own attorney immediately.</b></Text>
            </Stack.Item>


            <br />
            <Stack styles={categoryStackStyles}>
                <Stack.Item styles={headerStackItemStyles}>
                    <Text variant="mediumPlus">COMPLAINT</Text>
                </Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Complaint.pdf">Complaint.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Complaint Appx. A - WHOIS Domains.pdf">Complaint Appx. A - WHOIS Domains.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Complaint Appx. B - Trademarks.pdf">Complaint Appx. B - Trademarks.pdf</Link></Stack.Item>
            </Stack>
            <Stack styles={categoryStackStyles}>
                <Stack.Item styles={headerStackItemStyles}>
                    <Text variant="mediumPlus">COURT ORDERS</Text>
                </Stack.Item>
                <Stack.Item  styles={linkStackItemStyles}><Link href="/1152/TRO & Order to Show Cause Re PI.PDF">TRO & Order to Show Cause Re PI.PDF</Link></Stack.Item>
                <Stack.Item  styles={linkStackItemStyles}><Link href="/1152/2023.12.13 - Order Unsealing the Action.pdf">2023.12.13 - Order Unsealing the Action.pdf</Link></Stack.Item>
                <Stack.Item  styles={linkStackItemStyles}><Link href="/1152/2023.12.20 - Preliminary Injunction Order.pdf">2023.12.20 - Preliminary Injunction Order.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Suppl. PI Order.pdf">Suppl. PI Order.pdf</Link></Stack.Item>
            </Stack>
            <Stack styles={categoryStackStyles}>
                <Stack.Item styles={headerStackItemStyles}>
                    <Text variant="mediumPlus">APPLICATION FOR EMERGENCY <i>EX PARTE</i> TEMPORARY RESTRAINING ORDER (TRO) AND PRELIMINARY INJUNCTION</Text>
                </Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Mot. for TRO & Order to Show Cause.pdf">Mot. for TRO & Order to Show Cause.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/MOL ISO Mot. for TRO  Order to Show Cause (002).pdf">MOL ISO Mot. for TRO  Order to Show Cause (002).pdf</Link></Stack.Item>
                
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/P. Boffa Decl. ISO Mot. for TRO.pdf">P. Boffa Decl. ISO Mot. for TRO.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/P. Boffa Decl. Ex. 1 - Boffa CV.pdf">P. Boffa Decl. Ex. 1 - Boffa CV.pdf</Link></Stack.Item>
                
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/S. Cambric Decl. ISO Mot. for TRO.pdf">S. Cambric Decl. ISO Mot. for TRO.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/S. Cambric Decl. Ex. 1 - Microsoft Services Agreement.pdf">S. Cambric Decl. Ex. 1 - Microsoft Services Agreement.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/S. Cambric Decl. Ex. 2 - CV and Certifications.pdf">S. Cambric Decl. Ex. 2 - CV and Certifications.pdf</Link></Stack.Item>
                
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/J. Rozbruch Decl. ISO Mot. for TRO.pdf">J. Rozbruch Decl. ISO Mot. for TRO.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 1 - Appendix A.pdf">J. Rozbruch Decl. Ex. 1 - Appendix A.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 2 - ICANN May 2010 Study.pdf">J. Rozbruch Decl. Ex. 2 - ICANN May 2010 Study.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 3 - ICANN's Role.pdf">J. Rozbruch Decl. Ex. 3 - ICANN's Role.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 4 - ICANN Registrar Accreditation Agreement.pdf">J. Rozbruch Decl. Ex. 4 - ICANN Registrar Accreditation Agreement.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 5 - ICANN UDRP.pdf">J. Rozbruch Decl. Ex. 5 - ICANN UDRP.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 6 - ICANN UDRP Rules.pdf">J. Rozbruch Decl. Ex. 6 - ICANN UDRP Rules.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 7 - Public Domain Registrar-Registrant Terms of Service.pdf">J. Rozbruch Decl. Ex. 7 - Public Domain Registrar-Registrant Terms of Service.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 8 - Microsoft v. Does TRO Order (E.D.N.Y. 2023).pdf">J. Rozbruch Decl. Ex. 8 - Microsoft v. Does TRO Order (E.D.N.Y. 2023).pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 9 - Microsoft v. Does TRO Order (E.D.N.Y. 2020).pdf">J. Rozbruch Decl. Ex. 9 - Microsoft v. Does TRO Order (E.D.N.Y. 2020).pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 10 - Sophos v. Does TRO Order (E.D. Va. 2020).pdf">J. Rozbruch Decl. Ex. 10 - Sophos v. Does TRO Order (E.D. Va. 2020).pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 11 - Microsoft v. Does TRO Order (E.D. Va. 2020).pdf">J. Rozbruch Decl. Ex. 11 - Microsoft v. Does TRO Order (E.D. Va. 2020).pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 12 - DXC Technology Company v. Does TRO Order (E.D. Va. 2020).pdf">J. Rozbruch Decl. Ex. 12 - DXC Technology Company v. Does TRO Order (E.D. Va. 2020).pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 13 - Google v. Starovikov TRO Order (S.D.N.Y. 2021).pdf">J. Rozbruch Decl. Ex. 13 - Google v. Starovikov TRO Order (S.D.N.Y. 2021).pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Rozbruch Decl. Ex. 14 - Microsoft Annual Report 2023.pdf">J. Rozbruch Decl. Ex. 14 - Microsoft Annual Report 2023.pdf</Link></Stack.Item>
            
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/M. Mason Decl. ISO Mot. for TRO.pdf">M. Mason Decl. ISO Mot. for TRO.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/M. Mason Decl. Ex 1 - Resume.pdf">M. Mason Decl. Ex 1 - Resume.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/M. Mason Decl. Ex. 2 - BazaCall.pdf">M. Mason Decl. Ex. 2 - BazaCall.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/M. Mason Decl. Ex. 3 - HTML Smuggling Surges.pdf">M. Mason Decl. Ex. 3 - HTML Smuggling Surges.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/M. Mason Decl. Ex. 4 - Octo Tempest.pdf">M. Mason Decl. Ex. 4 - Octo Tempest.pdf</Link></Stack.Item>

                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/C. Stangl Decl. ISO Mot. for TRO.pdf">C. Stangl Decl. ISO Mot. for TRO.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/C. Stangl Decl. Ex. 1 - CV.pdf">C. Stangl Decl. Ex. 1 - CV.pdf</Link></Stack.Item>

                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/J. Lyons Decl. ISO Mot. for TRO.pdf">J. Lyons Decl. ISO Mot. for TRO.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Lyons Decl. Ex. 1 - Lyons CV.pdf">J. Lyons Decl. Ex. 1 - Lyons CV.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Lyons Decl. Ex. 2 - Microsoft Services Agreement.pdf">J. Lyons Decl. Ex. 2 - Microsoft Services Agreement.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Lyons Decl. Ex. 3 - How to Submit funCAPTCHA token for Outlook, Hotmail Captcha.pdf">J. Lyons Decl. Ex. 3 - How to Submit funCAPTCHA token for Outlook, Hotmail Captcha.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Lyons Decl. Ex. 4 - How to Bypass Twitter funCAPTCHA using 1st Captcha.pdf">J. Lyons Decl. Ex. 4 - How to Bypass Twitter funCAPTCHA using 1st Captcha.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/J. Lyons Decl. Ex. 5 - How to Distinguish Between Different Types of reCAPTCHA.pdf">J. Lyons Decl. Ex. 5 - How to Distinguish Between Different Types of reCAPTCHA.pdf</Link></Stack.Item>
            </Stack>
            <Stack styles={categoryStackStyles}>
                <Stack.Item styles={headerStackItemStyles}>
                    <Text variant="mediumPlus">APPLICATION FOR EMERGENCY <i>EX PARTE</i> SUPPLEMENTAL PRELIMINARY INJUNCTION ORDER</Text>
                </Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Lyons Decl. ISO Mot. for Suppl. PI.pdf">Lyons Decl. ISO Mot. for Suppl. PI.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/MOL ISO Mot. for Suppl. PI.pdf">MOL ISO Mot. for Suppl. PI.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Mot. for Suppl. PI.pdf">Mot. for Suppl. PI.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Rozbruch Decl. ISO Mot. for Suppl. PI.pdf">Rozbruch Decl. ISO Mot. for Suppl. PI.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/Rozbruch Decl. Ex. 1 - 2023.12.07 - TRO & Order to Show Cause Re PI.PDF">Rozbruch Decl. Ex. 1 - 2023.12.07 - TRO & Order to Show Cause Re PI.PDF</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/Rozbruch Decl. Ex. 2 - Order Granting Ex Parte Motion to Supplement the Preliminary Injunction, Microsoft Corp. v. John Does 1-2,.pdf">Rozbruch Decl. Ex. 2 - Order Granting Ex Parte Motion to Supplement the Preliminary Injunction, Microsoft Corp. v. John Does 1-2,.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/Rozbruch Decl. Ex. 3 - Supplemental Preliminary Injunction Order, Microsoft Corp. v. John Does 1-2, No. 1-16-cv-00993 (E.D. Va. De.pdf">Rozbruch Decl. Ex. 3 - Supplemental Preliminary Injunction Order, Microsoft Corp. v. John Does 1-2, No. 1-16-cv-00993 (E.D. Va. De.pdf</Link></Stack.Item>
                <Stack.Item styles={linkStackItemStyles2}><Link href="/1152/Rozbruch Decl. Ex. 4 - Supplemental Injunction Order, Microsoft Corp. v. John Does 1-2, No. 1-19-cv-00716 (D.D.C. May 22, 2019).pdf">Rozbruch Decl. Ex. 4 - Supplemental Injunction Order, Microsoft Corp. v. John Does 1-2, No. 1-19-cv-00716 (D.D.C. May 22, 2019).pdf</Link></Stack.Item>
              </Stack>
            <Stack styles={categoryStackStyles}>
                <Stack.Item styles={headerStackItemStyles}>
                    <Text variant="mediumPlus">DISCOVERY</Text>
                </Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/2024.02.22 MSFT - Vietcombank Subpoena.pdf">2024.02.22 MSFT - Vietcombank Subpoena.pdf</Link></Stack.Item>           
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/2024.02.22 MSFT - PayPal Subpoena.pdf">2024.02.22 MSFT - PayPal Subpoena.pdf</Link></Stack.Item> 
                <Stack.Item styles={linkStackItemStyles}><Link href="/1152/2024.03.25 PayPal PTE Ltd. R45 Subpoena (Service on Defendants).pdf">2024.03.25 PayPal PTE Ltd. R45 Subpoena (Service on Defendants).pdf</Link></Stack.Item>        
            </Stack>            
            <Stack styles={categoryStackStyles}>
                <Stack.Item styles={headerStackItemStyles}>
                    <Text variant="mediumPlus">MISCELLANEOUS</Text>
                </Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/2023.12.18 - Ltr. to Judge Engelmayer re Status of Service & Proposed PI Order.pdf">2023.12.18 - Ltr. to Judge Engelmayer re Status of Service & Proposed PI Order.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/2023.12.18 - Proposed PI Order - Ex. 1 to Ltr. to Judge Engelmayer.pdf">2023.12.18 - Proposed PI Order - Ex. 1 to Ltr. to Judge Engelmayer.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/2023.12.18 - Appendix A to Proposed PI Order.pdf">2023.12.18 - Appendix A to Proposed PI Order.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/B. Markley Decl. ISO Clerk's Certificate of Default.pdf">2024.08.09 - B. Markley Decl. ISO Clerk's Certificate of Default.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Proposed Clerk's Certificate of Default.pdf">2024.08.09 - Proposed Clerk's Certificate of Default.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/Request for Certificate of Default.pdf">2024.08.09 - Request for Certificate of Default.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/8.12 B. Markley Decl. ISO Clerk's Certificate of Default.pdf">2024.08.12 - B. Markley Decl. ISO Clerk's Certificate of Default.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/8.12 Proposed Clerk_s Certificate of Default.pdf">2024.08.12 - Proposed Clerk's Certificate of Default.pdf</Link></Stack.Item>
            <Stack.Item styles={linkStackItemStyles}><Link href="/1152/8.12 Request for Certificate of Default.pdf">2024.08.12 - Request for Certificate of Default.pdf</Link></Stack.Item>
            
            </Stack>
            <Stack styles={categoryStackStyles}>
                <Stack.Item styles={headerStackItemStyles}>
                    <Text variant="mediumPlus">Contact us</Text>
                </Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Text>If you wish to contact us by e-mail, fax, phone or letter please contact us at:</Text></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Text>Brian Markley</Text></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Text>Cahill Gordon & Reindel LLP</Text></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Text>32 Old Slip, New York, NY 10005</Text></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Text>t: +1.212.701.3230</Text></Stack.Item>
                <Stack.Item styles={linkStackItemStyles}><Text>bmarkley@cahill.com</Text></Stack.Item>
            </Stack>
            <br />
            <Stack.Item><Link href="https://privacy.microsoft.com/en-us/privacystatement">Microsoft Privacy Statement – Microsoft privacy</Link></Stack.Item>
        </Stack>
    </Stack>
  );
};
